<script setup lang="ts">
const { isAuthenticated } = useAuth()

// TODO: this leads to issues, because the desk is changed inside the slot of this layout,
// which leads to rehydration issues. We should either:
// - move it to the page itself.
// - set the desk in a middleware.
const { currentDesk } = useCurrentDesk()
// Somehow, using this leads to warnings about composables being used in middleware. It is caused by
// the set-visitor-type middleware, which uses the useVisitorType composable, but I have no clue
// why that would lead to this warning.
const { visitorType } = useVisitorType()

const { organizationsPath } = useAppConfig()

const route = useRoute()
const isOnLocalSection = computed(() => {
  return route.path.includes('/lokaal') && currentDesk.value
})

const menuSlug = computed(() => {
  return visitorType.value == 'organization'
    ? 'meedoen_global_generic'
    : 'meedoen_local_newcomer'
})
const contentPathPrefix = computed(() => {
  return visitorType.value == 'organization'
    ? organizationsPath
    : `/${currentDesk.value?.slug}/nieuwkomer`
})
</script>

<template>
  <div class="absolute flex min-h-screen w-full flex-col overflow-x-hidden">
    <MeedoenHeader :show-tabs="true" />

    <PublicCmsSidebarMenu
      :key="menuSlug"
      :menu-slug="menuSlug"
      :content-path-prefix="contentPathPrefix"
    >
      <template #top-content>
        
        <div class="mb-6 flex h-6 w-full justify-center">
          <MeedoenHeaderTabs class="absolute left-0 top-0" />
        </div>
      </template>
    </PublicCmsSidebarMenu>

    
    <PublicCmsMenu
      :key="visitorType"
      :menu-slug="menuSlug"
      :content-path-prefix="contentPathPrefix"
    />

    <PublicAuthenticatedMenu v-if="isAuthenticated" />

    <slot name="container">
      <Container class="grow pb-4 pt-6">
        <div v-if="isOnLocalSection">
          <BaseMessage class="mb-4" color="primary">
            Je bent nu op de subsectie van {{ currentDesk?.azcName }}.
          </BaseMessage>
        </div>

        <slot />
      </Container>
    </slot>

    <TairoPanels />

    <MeedoenFooter>
      <ClientOnly><GoogleTranslate /></ClientOnly>
    </MeedoenFooter>

    <CookieConsent />
  </div>
</template>
